/* #region Fonts */
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Rubik:wght@300;400;500;700&display=swap");
/* #endregion */

/* #region Variables */
$color-darker: #00313c;

$color-pastel: #eaa794;
$color-blue: #abc7c9;
$color-brown: #d1ccbd;
$color-green: #96a480;
$color-orange: #85af9a;
$color-red: #FF0000;
/* #endregion */

/* #region Function */
@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}
/* #endregion */

/* #region Global */
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}
body {
  font-family: "Rubik", sans-serif;
  color: $color-darker;
  overflow-x: hidden;
  position: relative;
}
* {
  outline: none !important;
}
/* #endregion */

/* #region Typography */
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: "Playfair Display", serif;
}
.h1,
h1 {
  font-size: rem-calc(55);
  font-weight: 900;
}
.h2,
h2 {
  font-size: rem-calc(38);
  margin-bottom: rem-calc(30);
  font-weight: 700;
}
.h3,
h3 {
  font-size: rem-calc(36);
  font-weight: 900;
  margin-bottom: rem-calc(20);
}
.h4,
h4 {
  font-weight: 700;
  line-height: 1.33334;
  margin-bottom: rem-calc(18);
}
.h5,
h5 {
  font-weight: 700;
}
p {
  margin-bottom: rem-calc(24);
  // &:last-child:not(.lead){margin-bottom: 0;}
}
ol,
ul {
  li {
    font-size: rem-calc(16);
  }
}

[type="button"],
[type="submit"],
button {
  -webkit-appearance: none;
}
/* #endregion */
.bg {
  &-darkder {
    background-color: $color-darker;
  }
  &-pastel {
    background-color: $color-pastel;
  }
  &-pink {
    background-color: $color-blue;
  }
  &-blue {
    background-color: $color-darker;
  }
  &-brown {
    background-color: $color-brown;
  }
  &-green {
    background-color: $color-green;
  }
}

.text {
  &-white {
    color: #fff;
  }
  &-darker {
    color: $color-darker;
  }
  &-pastel {
    color: $color-pastel;
  }
  &-pink {
    color: $color-pastel;
  }
  &-blue {
    color: $color-blue;
  }
  &-brown {
    color: $color-brown;
  }
  &-green {
    color: $color-green;
  }
  &-red{
    color: $color-red;
  }
}
